.colorBarMain {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Create 6 columns with equal width */
    gap: 10px; /* Adjust the gap between grid items as needed */
  }
  
  .colorBarsub {
    /* Style for each grid item */
    display: flex;
    flex-direction: row;
    /* border: 1px solid #ccc;
    padding: 10px; */
    text-align: center;
    justify-content: flex-start;
  }

  .colorBartitle{
    font-size: 10px;
    text-align: left;
  }


  .colorBarbox{
    width:20px;
    height:20px;
    background:red;
    border-radius:4px;
    margin-right:5px
  }

  