* {
    margin:0;
    padding: 0;
  }
  .main-body{
    background: #ffffff;
    
  }


  .whistle{
    width: 20%;
    fill: #54A6E0;
    margin: 100px 40%;
    text-align: left;
  }
  
  .error-code{
    margin-top: -100px;
    margin-bottom: 20px;
    color: #455d7a;
    text-align: center;
    font-family: 'Raleway';
    font-size: 90px;
    font-weight: 800;

    
  }
  .error-message{
    color: #455d7a;
    text-align: center;
    font-family: 'Raleway';
    font-size: 30px;
    text-transform: uppercase;
  }

  .error-message_{
    color: red;
    font-size: 12px;
    position: relative;
  }