.timeline {
    list-style: none;
    padding: 20px 10px 20px;
    position: relative;
    margin: auto;
}

.timeline:before {
    top: 80px;
    bottom: 90px;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #eeeeee;
    left: 5%;
    margin-left: -1.5px;
}

.timeline>li {
    margin-bottom: 20px;
    position: relative;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li>.timeline-panel {
    width: 90%;
    float: right;
    border-radius: 6px;
    padding: 20px;
    position: relative;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline>li>.timeline-badge {
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 33%;
    left: 5%;
    margin-left: -25px;
    background-color: #3e98c7;
    z-index: 100;
    border-radius: 10px;
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    background-color: #fff;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timeline-heading {
    width: 70%;
}

.timeline-clock {
    width: 25%;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body>p,
.timeline-body>ul {
    margin-bottom: 0;
}

.timeline-body>p+p {
    margin-top: 5px;
}

.flex-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.flex-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.timeline-head-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.timeline-head-lft {
    font-size: 14px;
    font-weight: 600;
    color: #858B91;
    padding: 4px 4px 4px 0px;
    word-break: break-all;
    width: 80%;
    padding-bottom: 14px;
}

.timeline-head-rht {
    font-size: 12px;
    color: #b3b1b1;
    padding: 4px 4px 4px 4px;
    word-break: break-all;
    text-align: right;
}

.timeline-discription {
    font-size: 10px;
    color: #b3b1b1;
    padding: 4px 4px 4px 4px;
    word-break: break-all;
}
.timeline-badge-image{
    width: 40px;
    height: 40px;
    padding: 6px;
    margin: auto;
    /* color: #2c2828; */
    border-radius: 5px;
}
.timeline-action-image{
    width: 20px;
    height: 20px;
    margin-left: 21px;
    
    color: #2c2828;
}