.callscreen_main {
    background-color: '#e9ecef';
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.callscreen_main-body {
    padding: 10px;
    margin-bottom: 10px;
}
.callscreen-flx {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
.callscreen-flx-top{
    overflow: hidden;
    overflow-y: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.callscreen-flx-bottom{
    z-index: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.callscreen-flx-top-main-div{
height: 100%;
}
.callscreen-header-div{
    height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
    flex: 1;
}
.callscreen-header {
       height: 32px;
}
.callscreen-header span {
       font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #858B91;
    width: 30%;
    display: inline-block;
    text-align: center;
    padding: 7px 0;
    border-bottom: 1px solid rgb(40 48 54 / 6%);
}
.callscreen-header  {
  
    border-bottom: 1px solid rgb(40 48 54 / 6%);
   }

.callscreen-header span.active {
    color: #2196f3;
    border-bottom: 2px solid #2196f3;
}

.callscreen-log{
    height: calc(100% - 40px);
    padding: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.callscreen-call-option {
    margin-top: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-evenly;
}

.callscreen-call-option span.active {
    background-color: #2196f3;
    color: #fff;
    border-radius: 5px;
}

.callscreen-call-option span {
        padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
}

.callscreen-call-option span:hover:not(.active) {
    background-color: #fff;
    color: #2196f3;
    border-radius: 5px;
}

.callscreen-body{
    height: 150px;
}
.callscreen-log-div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 100%;


}
.callscreen-log-div-top{
    height: 40px;
}
.callscreen-log-div-bottom{
    height: calc(100% - 40px);
}

.callscreen-body-option{
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
}

.callscreen-search-icon{
    position: absolute;
    top: 17px;
    left: 10px;
    font-size: 18px;
}

.callscreen-contact-icon{
    position: absolute;
    top: 17px;
    right: 10px;
    font-size: 18px;
}

.callscreen-autodial{
    box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
    margin: 5px;
    height: 40px;
    display: flex;
    flex-direction: row;
    position: relative;
}

.callscreen-autodial-head{
    font-weight: 600;
    color: #2196f3;
    font-size: 17px;
    margin: 0px;
}
.autodial{
    margin: 2px;
    padding: 10px ;
}
.autodial-list-bts{
    background-color: #3e98c7;
    border-radius: 10px;
    color: #fff !important;
}
